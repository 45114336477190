export const LOGIN = 'LOGIN';
export const setLogin = payload => ({ type: 'LOGIN', payload });

export const LOGIN_LOADED = 'LOGIN_LOADED';
export const setLoginLoad = payload => ({ type: 'LOGIN_LOADED', payload });

export const ROLE_LOADED = 'ROLE_LOADED';
export const setRoleLoad = payload => ({ type: 'ROLE_LOADED', payload });

export const TUTOR_LOADED = 'TUTOR_LOADED';
export const setTutorLoad = payload => ({ type: 'TUTOR_LOADED', payload });

export const TUTORIAL_LOADED = 'TUTORIAL_LOADED';
export const setTutorialLoad = payload => ({ type: 'TUTORIAL_LOADED', payload });

export const ENROLLED_LOADED = 'ENROLLED_LOADED';
export const setEnrolledLoad = payload => ({ type: 'ENROLLED_LOADED', payload });

export const SESSION_LOADED = 'SESSION_LOADED';
export const setSessionLoad = payload => ({ type: 'SESSION_LOADED', payload });

export const ATTENDANCE_LOADED = 'ATTENDANCE_LOADED';
export const setAttendanceLoad = payload => ({ type: 'ATTENDANCE_LOADED', payload });

export const YEAR = 'YEAR';
export const setYear = payload => ({ type: 'YEAR', payload });

export const SEMESTER = 'SEMESTER';
export const setSemester = payload => ({ type: 'SEMESTER', payload });

export const TYPES = 'TYPES';
export const setTypes = payload => ({ type: 'TYPES', payload });

export const CAROUSELS = 'CAROUSELS';
export const setCarousels = payload => ({ type: 'CAROUSELS', payload });

export const CAROUSEL_LOADED = 'CAROUSEL_LOADED';
export const setCarouselLoad = payload => ({ type: 'CAROUSEL_LOADED', payload });

export const CARDS = 'CARDS';
export const setCards = payload => ({ type: 'CARDS', payload });

export const CARD_LOADED = 'CARD_LOADED';
export const setCardLoad = payload => ({ type: 'CARD_LOADED', payload });
