import { createStore } from 'redux';
import { reducers } from '../reducers';
const initialState = {
    LOGIN: null,
    LOGIN_LOADED: true,
    ROLE_LOADED: true,
    TUTOR_LOADED: true,
    TUTORIAL_LOADED: true,
    ENROLLED_LOADED: true,
    SESSION_LOADED: true,
    ATTENDANCE_LOADED: true,
    YEAR: 2020,
    SEMESTER: 1,
    TYPES: {},
    CAROUSELS: null,
    CAROUSEL_LOADED: true,
    CARDS: null,
    CARD_LOADED: true
};

export const store = createStore(reducers, initialState,
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
);