import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './css/bootstrap.min.css';
import './css/scrollbars.css';
import './css/style.css';
import Axios from 'axios';

window.api = async function (ref, action, data, callback, error) {
	if (!data) data = {}
	data._ = Date.now();
	const formdata = new FormData();
	formdata.append('_ACTION', action);
	Object.keys(data).forEach(key => formdata.append(key, data[key]));
	let response = null;
	await Axios.post('/php/api/' + ref + '.php', formdata,
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	).then(value => response = value.data.type !== "Error" ?
		callback ? callback(value.data) : null
		:
		error ? error(value.data.value) : null
	).catch(reason => response = error ? error({ errno: -1, errstr: reason.message }) : null);
	return response;
}

window.capitalize = function (str) {
	return str.toLowerCase()
		.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
		.replace(/\sx|\six|\sviii|\svii|\svi|\sv|\siv|\siii|\sii|\si/gi, a => a.toUpperCase());
}

window.dateFormat = function (date) {
	return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + (date.getDate())
}

window.dayWeek = [
	"Domingo",
	"Lunes",
	"Martes",
	"Miércoles",
	"Jueves",
	"Viernes",
	"Sábado"
]

window.addDays = function (date, days) {
	date = new Date(date.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

window.getDates = function (day, startDate, stopDate) {
	const dateArray = [];
	let currentDate = startDate;
	while (currentDate.getDay() !== day) {
		currentDate = window.addDays(currentDate, 1);
	}
	while (currentDate <= stopDate) {
		dateArray.push(new Date(currentDate));
		currentDate = window.addDays(currentDate, 7);
	}
	return dateArray;
}

window.verifyRUT = function (n, k) {
	n = Number.parseInt(n, 10);
	let l = n.toString().length;
	let s = 0;
	for (let i = 0; i < l; i++) {
		s += ((n % Math.pow(10, i + 1)) - (n % Math.pow(10, i))) / Math.pow(10, i) * ((i % 6) + 2)
	}
	s = 11 - (s % 11)
	if (s === 11) { s = '0'; }
	else if (s === 10) { s = 'k'; }
	else { s = s.toString(); }
	return (k === s)
}

window.getUrlArgs = function () {
	let [, _args] = window.location.href.split('?');
	if (!_args) _args = "";
	_args = _args.split('&').filter(e => e).map(e => e.split('=', 2));
	const args = {}
	_args.forEach(e => args[e[0]] = e.length === 2 ? e[1] : true);
	return args;
}

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();