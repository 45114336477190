import {
    LOGIN,
    LOGIN_LOADED,
    ROLE_LOADED,
    TUTOR_LOADED,
    TUTORIAL_LOADED,
    ENROLLED_LOADED,
    SESSION_LOADED,
    ATTENDANCE_LOADED,
    YEAR,
    SEMESTER,
    TYPES,
    CAROUSELS,
    CAROUSEL_LOADED,
    CARDS,
    CARD_LOADED
} from '../actions';

export const reducers = (state, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, LOGIN: action.payload }
        case LOGIN_LOADED:
            return { ...state, LOGIN_LOADED: action.payload }
        case ROLE_LOADED:
            return { ...state, ROLE_LOADED: action.payload }
        case TUTOR_LOADED:
            return { ...state, TUTOR_LOADED: action.payload }
        case TUTORIAL_LOADED:
            return { ...state, TUTORIAL_LOADED: action.payload }
        case ENROLLED_LOADED:
            return { ...state, ENROLLED_LOADED: action.payload }
        case SESSION_LOADED:
            return { ...state, SESSION_LOADED: action.payload }
        case ATTENDANCE_LOADED:
            return { ...state, ATTENDANCE_LOADED: action.payload }
        case YEAR:
            return { ...state, YEAR: action.payload }
        case SEMESTER:
            return { ...state, SEMESTER: action.payload }
        case TYPES:
            return { ...state, TYPES: action.payload }
        case CAROUSELS:
            return { ...state, CAROUSELS: action.payload }
        case CAROUSEL_LOADED:
            return { ...state, CAROUSEL_LOADED: action.payload }
        case CARDS:
            return { ...state, CARDS: action.payload }
        case CARD_LOADED:
            return { ...state, CARD_LOADED: action.payload }
        default:
            return state;
    }
}