import React, { Component } from 'react';
import { connect } from 'react-redux';
import favicon from '../images/favicon-96x96.png';
import { Link } from 'react-router-dom';
import {
	Collapse,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTh, faSignInAlt, faEnvelope, faBell, faUser, faCog, faQuestionCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

class Header extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}

	componentDidMount() {
		document.onclick = event => {
			const nil = document.querySelectorAll('.Header .dropdown-toggle, .Header .dropdown-toggle *,.Header .navbar-toggler, .Header .navbar-toggler *')
			if (Object.values(nil).filter(e => e === event.target).length) return;
			if (!this.state.isOpen) return;
			this.toggle()
		}
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		const login = this.props.getLogin();
		return (
			<div className="Header">
				<Navbar color="primary" dark expand="md" fixed="top">
					<NavbarBrand tag={Link} to="/"><img id="favicon" src={favicon} alt="favicon" /> Tutores</NavbarBrand>
					{this.props.disabled ? null :
						<>
							<NavbarToggler onClick={this.toggle} />
							<Collapse isOpen={this.state.isOpen} navbar>
								<Nav navbar>
									<NavItem>
										<NavLink tag={Link} to="/"><FontAwesomeIcon icon={faHome} /> Inicio</NavLink>
									</NavItem>
									{
										login ?
											<NavItem>
												<NavLink tag={Link} to="/services"><FontAwesomeIcon icon={faTh} /> Servicios</NavLink>
											</NavItem>
											:
											null
									}
								</Nav>
								<Nav className="ml-auto" navbar>
									{
										login ?
											<>
												{/* <NavItem>
													<NavLink tag={Link} to="/messages"><FontAwesomeIcon icon={faEnvelope} /> Mensajes</NavLink>
												</NavItem> */}
												{/* <NavItem>
													<NavLink tag={Link} to="/notifications"><FontAwesomeIcon icon={faBell} /> Notificaciones</NavLink>
												</NavItem> */}
												<UncontrolledDropdown nav inNavbar>
													<DropdownToggle nav caret><FontAwesomeIcon icon={faUser} /> {login.name} {login.lastname}</DropdownToggle>
													<DropdownMenu right>
														<DropdownItem tag={Link} to={`/profile/${login.username}`}><FontAwesomeIcon icon={faUser} /> Mi perfil</DropdownItem>
														<DropdownItem tag={Link} to="/config"><FontAwesomeIcon icon={faCog} /> Configuración</DropdownItem>
														<DropdownItem divider />
														<DropdownItem tag={Link} to="/login"><FontAwesomeIcon icon={faSignOutAlt} /> Salir</DropdownItem>
														<DropdownItem tag={Link} to="/help"><FontAwesomeIcon icon={faQuestionCircle} /> Ayuda</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</>
											:
											<NavItem>
												<NavLink tag={Link} to="/login"><FontAwesomeIcon icon={faSignInAlt} /> Entrar</NavLink>
											</NavItem>
									}
								</Nav>
							</Collapse>
						</>
					}
				</Navbar>
			</div>
		);
	}
}

const stateToProps = state => ({
	getLogin: () => state.LOGIN
});

const dispatchToProps = dispatch => ({});

export default connect(stateToProps, dispatchToProps)(Header);