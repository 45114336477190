import React, { Component, Suspense, lazy } from 'react';
import Header from './components/Header';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Spinner, Card, CardHeader, CardBody } from 'reactstrap';


const HomeContent = lazy(() => import('./components/content/HomeContent'));
const LoginContent = lazy(() => import('./components/content/LoginContent'));
const ProfileContent = lazy(() => import('./components/content/ProfileContent'));
const ConfigContent = lazy(() => import('./components/content/ConfigContent'));
const ServicesContent = lazy(() => import('./components/content/ServicesContent'));
const ServicesTutorialsContent = lazy(() => import('./components/content/ServicesTutorialsContent'));
const ServicesTutorialsAttendanceContent = lazy(() => import('./components/content/ServicesTutorialsAttendanceContent'));
const ServicesTutorsContent = lazy(() => import('./components/content/ServicesTutorsContent'));
const ServicesTutorsTutorialsContent = lazy(() => import('./components/content/ServicesTutorsTutorialsContent'));
const ServicesTutorsTutorialsAttendanceContent = lazy(() => import('./components/content/ServicesTutorsTutorialsAttendanceContent'));
const ServicesManagerContent = lazy(() => import('./components/content/ServicesManagerContent'));
const ServicesReportsContent = lazy(() => import('./components/content/ServicesReportsContent'));
const ServicesLoadStudentsContent = lazy(() => import('./components/content/ServicesLoadStudentsContent'));

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			MANTENTION_MODE: false
		}
		window.app = this;
	}


	/*
	<Content content-type="ReportContent" stateApp={this.state} setStateApp={this.setStateApp.bind(this)} />
	<Content content-type="ManageContent" stateApp={this.state} setStateApp={this.setStateApp.bind(this)} />
	<Content content-type="MessagesContent" stateApp={this.state} setStateApp={this.setStateApp.bind(this)} />
	<Content content-type="NotificationsContent" stateApp={this.state} setStateApp={this.setStateApp.bind(this)} />
	<Content content-type="HelpContent" stateApp={this.state} setStateApp={this.setStateApp.bind(this)} />
	*/

	render() {
		return (
			<Router>
				<div className="App">
					<Header disabled={this.state.MANTENTION_MODE} />
					<div className="scrollbar scrollbar-primary" style={{ maxHeight: 'calc(100vh - 44px)', marginTop: '44px' }}>
						<main>
							{this.state.MANTENTION_MODE ?
								<div style={{
									display: "table",
									height: "calc(100vh - 140px - 30px)",
									width: "100%"
								}}>
									<div style={{
										height: "calc(100vh - 140px - 30px)",
										width: "100%",
										display: "table-cell",
										verticalAlign: "middle",
										textAlign: 'center'
									}}>
										<Card style={{
											marginLeft: 'auto',
											marginRight: 'auto',
											maxWidth: '320px'
										}}>
											<CardHeader className="bg-warning text-white p-1"><b>Atención</b></CardHeader>
											<CardBody className="p-2">Sítio en mantención. Por favor intentelo más tarde.</CardBody>
										</Card>
									</div>
								</div>
								:
								<Suspense fallback={
									<div style={{
										display: "table",
										height: "calc(100vh - 140px - 30px)",
										width: "100%"
									}}>
										<div style={{
											height: "calc(100vh - 140px - 30px)",
											width: "100%",
											display: "table-cell",
											verticalAlign: "middle",
											textAlign: 'center'
										}}>
											<Spinner color="light" />
										</div>
									</div>
								}>
									<Switch>
										<Route exact path="/" component={HomeContent} />
										<Route exact path="/login" component={LoginContent} />
										<Route exact path="/profile/:username" component={ProfileContent} />
										<Route exact path="/config" component={ConfigContent} />
										<Route exact path="/services" component={ServicesContent} />
										<Route exact path="/services/tutorials" component={ServicesTutorialsContent} />
										<Route exact path="/services/tutorials/:tutorialId/attendance" component={ServicesTutorialsAttendanceContent} />
										<Route exact path="/services/tutors" component={ServicesTutorsContent} />
										<Route exact path="/services/tutors/:tutorId/tutorials" component={ServicesTutorsTutorialsContent} />
										<Route exact path="/services/tutors/:tutorId/tutorials/:tutorialId/attendance" component={ServicesTutorsTutorialsAttendanceContent} />
										<Route exact path="/services/manager" component={ServicesManagerContent} />
										<Route exact path="/services/reports" component={ServicesReportsContent} />
										<Route exact path="/services/load/students" component={ServicesLoadStudentsContent} />
									</Switch>
								</Suspense>
							}
						</main>
						<footer>
							<p>
								Esta plataforma se encuentra en constante desarrollo, por lo que cualquier error o comentario, enviarlo por wsp a <code>+56 9 9092 4328</code>
							</p>
						</footer>
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
